<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-09-08 17:59:54
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-09-09 08:50:36
-->
<template>
  <div class="witnessDetail">
    <div class="title">{{ detail.teachingWitnessName }}</div>
    <div class="time">
      <div class="text">
        <img src="../../../assets/teacher/time.png" alt="" />
        <div class="textContent">{{ detail.createTime }}</div>
      </div>
      <div class="text ml">
        <img src="../../../assets/teacher/eye.png" alt="" />
        <div class="textContent">{{ detail.pageViews }}</div>
      </div>
    </div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="content" v-html="detail.teachingWitnessIntroduction"></div>
  </div>
</template>

<script>
import to from 'await-to'
import { witnessDetail } from '@/api/teacher'
export default {
  data() {
    return {
      teachingWitnessId: this.$route.query.teachingWitnessId,
      detail: {},
    }
  },
  created() {
    this.witnessDetailData()
  },
  methods: {
    async witnessDetailData() {
      const [res, err] = await to(witnessDetail({ teachingWitnessId: this.teachingWitnessId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.detail = res.data
    },
  },
}
</script>

<style scoped lang="scss">
.witnessDetail {
  padding: 40px 0;
  text-align: center;
  background-color: #fff;
  .title {
    font-weight: 500;
    font-size: 16px;
    color: #333;
  }
  .time {
    display: flex;
    justify-content: center;
    .text {
      display: flex;
      align-items: center;
      color: #909399;
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: 20px;
      .textContent {
        margin-left: 5px;
      }
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
  .content {
    color: #3e3e3e;
    line-height: 20px;
    font-size: 14px;
  }
  .ml {
    margin-left: 40px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
